<template>
  <div class="mcc_culture con_area_bgcolor">
    <div class="mcc_project_con con_area">
      <div class="w11402">
        <h2>
          <span class="title">Cultural Publicity and Exchange</span>
        </h2>
        <div>
          <el-tabs
            :tab-position="cWidth > 1024 ? 'left' : 'top'"
            style="min-height: 300px"
          >
            <el-tab-pane
              label="Chinese Festival Food and Cooking Demonstration"
            >
              <h1>Chinese Festival Food and Cooking Demonstration</h1>
              <!-- <h1>中国节日美食和烹饪示范</h1> -->
              <span class="justify_align"
                >Here are the dates of the main Chinese traditional festivals
                throughout 2023, along with some traditional foods that people
                consume during each festival:</span
              >
              <!-- <span class="justify_align"
                >以下是2023年中国主要传统节日的日期，以及人们在每个节日期间食用的一些传统食物：</span
              > -->
              <h2>
                <i class="iconfont icon-shichanghuodong"></i>Chinese New Year
                (Spring Festival): February 1-15, 2023
              </h2>
              <ul>
                <li>Tang Yuan (sweet rice balls)</li>
                <li>Glutinous rice cakes (Nian Gao)</li>
                <li>Spring rolls (Chun Juan)</li>
                <li>Sweet rice balls (Tang Yuan)</li>
              </ul>
              <h2>
                <i class="iconfont icon-shichanghuodong"></i>Lantern Festival:
                February 16, 2023
              </h2>
              <ul>
                <li>Dumplings (Jiaozi)</li>
                <li>Yuan Xiao (glutinous rice balls)</li>
              </ul>
              <h2>
                <i class="iconfont icon-shichanghuodong"></i>Qingming Festival
                (Tomb Sweeping Day): April 4, 2023
              </h2>
              <ul>
                <li>Qingming cake (Qingming Bing)</li>
              </ul>
              <h2>
                <i class="iconfont icon-shichanghuodong"></i>Dragon Boat
                Festival: June 2, 2023
              </h2>
              <ul>
                <li>Zongzi (sticky rice dumplings)</li>
                <li>Rice wine (Xionghuangjiu)</li>
                <li>Salted eggs</li>
              </ul>
              <h2>
                <i class="iconfont icon-shichanghuodong"></i>Mid-Autumn Festival
                (Moon Festival): September 10, 2023
              </h2>
              <ul>
                <li>Mooncakes (Yue Bing)</li>
                <li>Osmanthus jelly (Gui Hua Gao)</li>
                <li>Pomelos (Youzi)</li>
              </ul>
              <h2>
                <i class="iconfont icon-shichanghuodong"></i>Double Ninth
                Festival: October 28, 2023
              </h2>
              <ul>
                <li>Chongyang cake (Chongyang Gao)</li>
                <li>Chrysanthemum tea (Ju Hua Cha)</li>
              </ul>
            </el-tab-pane>
            <el-tab-pane label="Chinese Health and Wellbeing">
              <h1>Chinese Health and Wellbeing</h1>
              <!-- <h1>中国人的健康与福祉</h1> -->
              <p class="justify_align">
                This programme is designed to help the new comers of students,
                elders and Chinese families in our community to cope with their
                live in New Zealand; help with their physically and
                psychologically access to the society. Strengthening the
                community connectedness, improve social wellbeing, which then
                will increase our social amenity and build up a stronger bond.
                Enhance a stronger relationship between individuals also with
                other ethnic groups and local community.
              </p>
              <ul class="justify_align">
                <li>
                  <i class="iconfont icon-jiankang1"></i>
                  Monthly gatherings for workshops, seminars etc. gathering of
                  different Chinese groups in Palmerston North, to share
                  information and social with each other. Introductions on New
                  Zealand’s policies on health systems, social well-being and
                  spreading information on social benefits and health services.
                </li>
                <li>
                  <i class="iconfont icon-jiankang1"></i>
                  Invite experts and or specialists from China and other cities
                  as well of New Zealand, play visits to the community, give
                  free consultations on traditional Chinese Medicines related to
                  health and wellbeing in Chinese Language.
                </li>
                <li>
                  <i class="iconfont icon-jiankang1"></i>
                  Visit households consists of elder people frequently, get to
                  know them and what their needs are. Also we will help them to
                  make appointments with GP, and bring them to medical centre if
                  needed.
                </li>
                <li>
                  <i class="iconfont icon-jiankang1"></i>
                  In contact with the Massey university Chinese Student
                  Association. Our plan with the students are considered in four
                  main aspects. Firstly help them with their English and
                  communication skill, which encourage them to blend into the
                  study environment soonest possible; secondly provide
                  transportation for social activities if needed, even
                  organizing social activities for the students, especially on
                  traditional festivals. Thirdly, assist them if they are in
                  trouble, such as needed medical attention, or violation
                  towards their human body or human right. Fourthly, educate and
                  advertise how the social network in New Zealand works, and
                  providing information regarding all aspect of this country.
                </li>
                <li>
                  <i class="iconfont icon-jiankang1"></i>
                  Keep working on supporting the finding of a Chinese GP in
                  Palmerston North. And always be there to help with the
                  establishment of a Chinese herb shop or wellbeing services
                  business, if such opportunity presented.
                </li>
              </ul>
            </el-tab-pane>
            <el-tab-pane label="Mcct on Social media platforms">
              <h1>Mcct on Social media platforms</h1>
              <!-- <h1>社交媒体平台上的Mcct</h1> -->
              <p class="justify_align">
                An information and communication platform APP is to be developed
                by combining the existing Chinese Hotline, MCCT website and
                Chinese language Wechat 58.com to provide free, comprehensive
                local information to the local community, and the meanwhile
                people can communicate and seek support through this functional
                project.
              </p>
              <p class="justify_align">
                With this platform, our Chinese audience could have a better
                understanding of what are happening in local community, what
                resources they have access to, what services/products they could
                use, and so on. It would improve the living convenience for the
                Chinese community. Furthermore, it enhances to unite all the
                Chinese communities, groups and individuals together.
              </p>
              <p class="justify_align">
                At the same time, with more Chinese community members taking
                part in local activities or using local services/products, local
                community will benefit from the information sharing. On top of
                that, it improves multi-cultural civilization / diversity of New
                Zealand.
              </p>
              <h2>To improve Mcct social media platforms:</h2>
              <ul>
                <li>
                  <i class="iconfont icon-icon_weixin-logo"></i>wechat in
                  Chinese language
                </li>
                <li>
                  <i class="iconfont icon-lianshu"></i>Facebook in English
                </li>
                <li>
                  <i class="iconfont icon-yumingyuwangzhan"></i>website
                  bilingually -www.manawatuchinese.org.nz
                </li>
              </ul>
              <p class="justify_align">
                To provide information and resources, for example, Jobs
                recruitment, insurance, home safety, house for rent, health care
                tips… Also online discussion and communication platform where
                community members can share their comments and advice.To keep
                people in need informed of where to get help to avoid getting
                bounced around between agencies.
              </p>
            </el-tab-pane>
            <el-tab-pane label="Celebrating Chinese Festivals">
              <h1>Celebrating Chinese Festivals</h1>
              <!-- <h1>庆祝中国节日</h1> -->
              <p class="justify_align">
                Mcct organizes cultural events through a year in which Chinese
                culture is celebrated with performance, demonstrations and shows
                etc.
              </p>
              <ul class="justify_align">
                <li>
                  <i class="iconfont icon-shichanghuodong"></i
                  ><span>Chinese New Year</span> Concert to be held in the 1st
                  Chinese lunar month every year-Spring Festival, which normally
                  falls in Februarys.
                </li>
                <li>
                  <i class="iconfont icon-shichanghuodong"></i
                  ><span>Lantern Festival</span>, the 15th day of 1st lunar
                  month. Lantern parade and lion dance celebrating the first
                  full moon.
                </li>
                <li>
                  <i class="iconfont icon-shichanghuodong"></i
                  ><span>Qing Ming</span>, Tomb Sweeping Day, a time for people
                  to go outside and enjoy the greenery of springtime (treading
                  on the greenery) and tend to the graves of departed ones.
                </li>
                <li>
                  <i class="iconfont icon-shichanghuodong"></i
                  ><span>Duan wu Festival</span>, 5th day of 5th lunar month,
                  people eat dumplings wrapped in lotus leaves. Dragon boat race
                  is also referred as one of a symbols of Chinese traditional
                  sports.
                </li>
                <li>
                  <i class="iconfont icon-shichanghuodong"></i
                  ><span>The Mid-Autumn (Moon) Festival</span> is the second
                  important day in the Chinese calendar. Farmers celebrate the
                  harvesting season on this date. Family members and friends
                  will gather to admire the harvest moon, eat moon cakes. A full
                  moon is a symbol of peace and prosperity for the whole.
                </li>
                <li>
                  <i class="iconfont icon-shichanghuodong"></i>
                  <span>Exhibition</span> can be hold by the meaning of showing
                  different talents from the whole community. Information about
                  different diversify that community members can enhance the
                  understanding and awareness in the community.
                </li>
                <li>
                  <i class="iconfont icon-shichanghuodong"></i>
                  <span>Volunteer work</span> with the combination of community
                  members and students to enhance the connection about each
                  other.
                </li>
              </ul>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cWidth: sessionStorage.getItem("clientWidth"),
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style>
.mcc_culture {
  width: 100%;
}
</style>